

















































































































































































































































































































































































































































































































































































































































@import '~@/assets/styles/components/formularios';
@import '~@/assets/styles/components/modal-cadastro';

.box-table::v-deep {
  .cancelado {
    color: #F00;
  }

  &-row {
    cursor: pointer;
  }

  .btn-remove {
    height: auto !important
  }

  .v-slide-group__prev,
  .v-slide-group__next {
    display: none !important;
  }

  table {
    min-width: 360px;
  }
}

